import Container from '@mui/material/Container';
import Drawer from '@mui/material/Drawer';
import React, { useCallback, useEffect, useState } from 'react';

import { AuthScreenMode } from '../../model/screen';
import { useAuthContext } from '../../state/auth';
import { EventHub, MappedEvents } from '../../utils/event';

import ForgotPassword from './ForgotPassword';
import Login from './Login';
import Register from './Register';

const Auth: React.FC = () => {
  const [authScreen, setAuthScreen] = useState<AuthScreenMode>();
  const { auth } = useAuthContext();

  useEffect(() => {
    // setAuthScreen(auth.isLoggedIn ? undefined : 'login'); remove login ask to not confuse client
  }, [auth]);

  const loginScreen = useCallback(() => setAuthScreen('login'), []);

  useEffect(() => {
    EventHub.on(MappedEvents.LoginAttempt, loginScreen);
    return () => EventHub.off(MappedEvents.LoginAttempt, loginScreen);
  }, [loginScreen]);

  const handleOnDrawerClose = useCallback(() => setAuthScreen(undefined), []);

  return (
    <Drawer
      anchor="bottom"
      open={Boolean(authScreen)}
      onClose={handleOnDrawerClose}
    >
      <Container sx={style.container}>
        {authScreen === 'login' && <Login setAuthScreen={setAuthScreen} />}
        {authScreen === 'register' && <Register setAuthScreen={setAuthScreen} />}
        {authScreen === 'forgot-password' && <ForgotPassword setAuthScreen={setAuthScreen} />}
      </Container>
    </Drawer>
  );
};

const style = {
  container: {
    padding: 0,
    height: '332px',
    width: '100%',
  }
}

export default Auth;
